import React, { Suspense, lazy } from 'react';
import { Routes, Route } from 'react-router-dom';
import '@fontsource/poppins';

// Usando React.lazy para carregar as páginas de forma assíncrona
const CostumerRegistration = lazy(() => import('../pages/CostumerRegistration'));
const CompatibleDevices = lazy(() => import('../pages/CompatibleDevices'));
const Home = lazy(() => import('../pages/Home'));
const MyProfile = lazy(() => import('../pages/MyProfile'));
const Payment = lazy(() => import('../pages/Payment'));
const PlansEUA = lazy(() => import('../pages/PlanEUA'));
const PlansEurope = lazy(() => import('../pages/PlanEurope'));
const PlanArg = lazy(() => import('../pages/PlanArg'));
const PlanChile = lazy(() => import('../pages/PlanChile'));
const PlanUruguai = lazy(() => import('../pages/PlanUruguai'));
const PlanMexico = lazy(() => import('../pages/PlanMexico'));
const PlanParaguai = lazy(() => import('../pages/PlanParaguai'));
const PlanColombia = lazy(() => import('../pages/PlanColombia'));
const PlanRepDominicana = lazy(() => import('../pages/PlanRepDominicana'));
const PolicyAuth = lazy(() => import('../pages/PolicyAuth'));
const TermsOfService = lazy(() => import('../pages/TermsOfService'));
const Login = lazy(() => import('../pages/Login'));
const EsimActive = lazy(() => import('../pages/EsimActive'));
const NotFound = lazy(() => import('../pages/NotFound'));

const AppRoutes = () => {
    return (
        <Suspense fallback={<div></div>}> {/* Um spinner ou texto de loading */}
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/home" element={<Home />} />
                <Route path="/plans-EUA" element={<PlansEUA />} />
                <Route path="/plans-Europe" element={<PlansEurope />} />
                <Route path="/plans-Europe/:countryName" element={<PlansEurope />} />
                <Route path="/plans-Argentina" element={<PlanArg />} />
                <Route path="/plans-Chile" element={<PlanChile />} />
                <Route path="/plans-Uruguai" element={<PlanUruguai />} />
                <Route path="/plans-Mexico" element={<PlanMexico />} />
                <Route path="/plans-Paraguai" element={<PlanParaguai />} />
                <Route path="/plans-Colombia" element={<PlanColombia />} />
                <Route path="/plans-RepDominicana" element={<PlanRepDominicana />} />
                <Route path="/payment" element={<Payment />} />
                <Route path="/register" element={<CostumerRegistration />} />
                <Route path="/Compatible-Devices" element={<CompatibleDevices />} />
                <Route path="/login" element={<Login />} />
                <Route path="/policyAuth" element={<PolicyAuth />} />
                <Route path="/termsOfService" element={<TermsOfService />} />
                <Route path="/Meu-Perfil/:tab" element={<MyProfile />} />
                <Route path="/Como-Ativar" element={<EsimActive />} />
                <Route path="*" element={<NotFound />} />
            </Routes>
        </Suspense>
    );
};

export default AppRoutes;
